.header-container{
	position: relative;
	min-height: 67px;
}

.banner{
	&.header{
  	position: relative;
	}
}

.header-top{
	position: relative;
  width: 100%;
	@media (min-width: $screen-sm-min) {
		position: absolute;
		top: 0;
	  right: 0;
		height: 85px;
	}
}

.header-upper-menu{
	display: none;
	li{
	  display: inline-block;
	  border-right: 1px solid #000;
	  padding: 0 5px;
	  line-height: 14px;
		&:last-child{
		  border-right: 0 solid #000;
		}
		a{
		  display: inline-block;
		  color: #000;
		}
	}
	@media (min-width: $screen-sm-min) {
		position: absolute;
		display: block;
		top: 12px;
		right: 35px;
	}
	@media (min-width: $screen-md-min) {
		position: absolute;
	  display: block;
	  top: 12px;
	  right: 195px;
	}
}

.search-button{
  display: none;
  font-size: 16px;
  color: #000;
	background: rgba(0, 0, 0, 0.6);
	@media (min-width: $screen-sm-min) {
		position: absolute;
	  top: 15px;
	  right: 0;
		display: block;
	  padding: 4px 6px;
	  border: 1px solid #000;
	}
	@media (min-width: $screen-md-min) {
		position: absolute;
	  top: 15px;
	  right: 150px;
		display: block;
	  padding: 4px 6px;
	  border: 1px solid #000;
	}
}

.phone-numbers{
  position: relative;
  display: block;
	list-style-type: none;
	padding-top: 10px;
	margin-left: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
	@media screen and (min-width: $screen-sm-min){
		padding-top: 15px;
		top: 17px;
	}
	li{
	  display: block;
	  padding: 0 5px;
	  line-height: 18px;
		width: 100%;
	  color: #000;
	  font-size: 18px;
		text-align: center;
		@media (min-width: $screen-sm-min) {
			display: inline-block;
			border-right: 1px solid #000;
			line-height: 14px;
			font-size: 14px;
			width: auto;
			text-align: left;
		}
		&:last-child{
			border-right: 0 solid #000;
		}
		a{
			font-weight: 600;
		  color: #000;
		}
	}
	@media (min-width: $screen-sm-min) {
		position: absolute;
		top: 17px;
		right: 35px;
	}
	@media (min-width: $screen-md-min) {
	  right: 195px;
	}
}

.header{
	.brand{
		position: relative;
		display: block;
		padding: 12px 0px 5px 0px;
		width: 100%;
		margin: 10px 0;
		img{
			display: block;
			width: 100%;
			height: auto;
		}
		@media (min-width: $screen-md-min) {
			margin: 0;
		}
	}
	.header-cta{
	  position: relative;
		&.upper{
			display: none;
			@media (min-width: $screen-md-min) {
				display: block;
				position: absolute;
				top: 0;
			  right: 0;
			}
		}
		&.lower{
			img{
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
			@media (min-width: $screen-sm-min) {
				display: none;
			}
		}
	}
	@media (min-width: $screen-sm-min) {
		.brand{
			display: inline-block;
			padding: 15px 0;
			max-width: 500px;
			img{
				display: block;
				width: 80%;
				height: auto;
			}
		}
	}
	@media (min-width: $screen-md-min) {
		.brand{
			max-width: 600px;
		}
	}
}

.nav-primary{
	@extend .navbar;
	@extend .navbar-default;
	position: relative;
	background-color: rgba(0, 0, 0, 1);
	border: none;
	border-radius: 0;
	z-index: 1000;
	.navbar{
		border-radius: none;
	}
	.nav-primary-list{
		margin: 0px 0;
		@media screen and (min-width: $screen-md-min){
			margin: 0px -15px;
		}
		>li{
			a{
				padding-left: 40px;
				text-transform: uppercase;
				color: $brand-white;
				.caret{
					display: none;
				}
				@media (min-width: $screen-sm-min) {
					padding: 10px 15px;
					padding-left: 15px;
				}
			}
			&.active{
				>a{
					color: $brand-white;
					background-color: #666;
				}
			}
		}

	}
	@media screen and (min-width: 991px){
		.dropdown-menu li:hover .sub-menu {
	    visibility: visible;
		}
		.dropdown:hover .dropdown-menu {
	    display: block;
		}
	}
}

.home{
	.nav-primary{
		background-color: rgba(0, 0, 0, 0.7);
		margin-bottom: -50px;
	}
}

.btn.btn-primary.header-cta.upper{
	z-index: 1500;
}
.booking-collapse{
	z-index: 1500;
}
.navbar-header{
	.btn.btn-primary.header-cta.upper{
		height: 67px !important;
	}
	.btn.btn-primary.header-cta.upper.in-bar{
		display: inline-block;
		position: relative;
		margin-left: 15px;
		height: 50px !important;
		padding: 15px 15px 0px 15px !important;
		box-shadow: none;
		@media screen and (min-width: $screen-md-min){
			/*display: none; */
		}
	}
}

.picker-box .picker{
	width: 100%;
}
